import { AppBar, Avatar, Box, ButtonBase, Toolbar } from "@mui/material";
import React from "react";
import LogoSection from "../LogoSection";
import { useTheme } from "@emotion/react";
import MenuIcon from "@mui/icons-material/Menu";
import LogoutIcon from "@mui/icons-material/LogoutRounded";
import { useAppContext } from "../../context/AppContext";
import { useNavigate } from "react-router-dom";

const Header = ({ leftDrawerOpened, handleLeftDrawerToggle }) => {
  const theme = useTheme();
  const { logout } = useAppContext();

  const navigate = useNavigate();

  return (
    <>
      <AppBar
        position="fixed"
        elevation={0}
        color="inherit"
        sx={{
          bgcolor: theme.palette.background.default,
          transition: leftDrawerOpened
            ? theme.transitions.create("width")
            : "none",
        }}
      >
        <Toolbar>
          <Box
            sx={{
              width: 228,
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Box
              sx={{
                display: { xs: "none", md: "flex" },
              }}
            >
              <LogoSection />
            </Box>
            <ButtonBase sx={{ borderRadius: "8px", overflow: "hidden" }}>
              <Avatar
                variant="rounded"
                sx={{
                  ...theme.typography.commonAvatar,
                  ...theme.typography.mediumAvatar,
                  transition: "all .2s ease-in-out",
                  background: theme.palette.primary.light,
                  color: theme.palette.primary.dark,
                  "&:hover": {
                    background: theme.palette.primary.dark,
                    color: theme.palette.primary.light,
                  },
                }}
                onClick={handleLeftDrawerToggle}
                color="inherit"
              >
                <MenuIcon stroke={1.5} size="1.3rem" />
              </Avatar>
            </ButtonBase>
          </Box>

          <Box sx={{ flexGrow: 1 }} />
          <ButtonBase sx={{ borderRadius: "8px", overflow: "hidden" }}>
            <Avatar
              sx={{
                ...theme.typography.commonAvatar,
                ...theme.typography.mediumAvatar,
                transition: "all .2s ease-in-out",
                background: theme.palette.secondary.light,
                color: theme.palette.secondary.dark,
                "&:hover": {
                  background: theme.palette.secondary.dark,
                  color: theme.palette.secondary.light,
                },
              }}
              onClick={() => {
                const url = logout();
                if (url) navigate(url);
              }}
              color="inherit"
            >
              <LogoutIcon stroke={1.5} size="1.3rem" />
            </Avatar>
          </ButtonBase>
        </Toolbar>
      </AppBar>
    </>
  );
};

export default Header;
