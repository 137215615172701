import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import EnhancedTableHead from "../../components/EnhancedTableHead";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import AnimateButton from "../../components/AnimateButton";
import StarsRoundedIcon from "@mui/icons-material/StarsRounded";
import { useAppContext } from "../../context/AppContext";

const headCells = [
  {
    id: "firstName",
    label: "First Name",
    sortable: true,
    align: "left",
  },
  {
    id: "lastName",
    label: "Last Name",
    align: "left",
    sortable: true,
  },
  {
    id: "organization",
    label: "Organization",
    align: "left",
    sortable: false,
  },
  {
    id: "email",
    label: "Email Id",
    align: "left",
    sortable: false,
  },
  {
    id: "action",
    label: "Action",
    align: "center",
    sortable: false,
  },
];

const MembersModel = ({
  open,
  handleClose,
  project,
  handleDeleteProjectMember,
}) => {
  const { loggedInUser } = useAppContext();
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle variant="h3">Project Members</DialogTitle>
      <DialogContent>
        {project && (
          <Box sx={{ width: "100%" }}>
            <Paper sx={{ width: "100%", mb: 2 }}>
              <TableContainer>
                <Table
                  sx={{ minWidth: 480 }}
                  aria-labelledby="tableTitle"
                  size="small"
                >
                  <EnhancedTableHead sortable={false} headCells={headCells} />
                  <TableBody>
                    {project?.projectMembers
                      .sort((a, b) => b.owner - a.owner)
                      .map((projectMember) => {
                        return (
                          <TableRow
                            hover
                            tabIndex={-1}
                            key={projectMember._id}
                            sx={{ cursor: "pointer" }}
                          >
                            <TableCell>{projectMember.firstName}</TableCell>
                            <TableCell>{projectMember.lastName}</TableCell>
                            <TableCell>{project?.organizationName}</TableCell>
                            <TableCell>
                              <Stack
                                direction="row"
                                alignItems="center"
                                gap={1}
                              >
                                <Typography>{projectMember.email}</Typography>
                                {projectMember.owner && (
                                  <StarsRoundedIcon color="primary" />
                                )}
                              </Stack>
                            </TableCell>
                            <TableCell align="center">
                              <Tooltip
                                title={
                                  loggedInUser?.role !== "org-member" &&
                                  "Delete Project Member"
                                }
                              >
                                <span>
                                  <IconButton
                                    size="large"
                                    tabIndex={-1}
                                    disabled={
                                      projectMember.owner ||
                                      loggedInUser?.role === "org-member"
                                    }
                                    onClick={(event) =>
                                      handleDeleteProjectMember(projectMember)
                                    }
                                  >
                                    <DeleteOutlinedIcon
                                      color={
                                        projectMember.owner ||
                                        loggedInUser?.role === "org-member"
                                          ? "disabled"
                                          : "error"
                                      }
                                    />
                                  </IconButton>
                                </span>
                              </Tooltip>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </Box>
        )}

        <Divider />
      </DialogContent>
      <DialogActions>
        <AnimateButton>
          <Button variant="contained" onClick={handleClose}>
            Close
          </Button>
        </AnimateButton>
      </DialogActions>
    </Dialog>
  );
};

export default MembersModel;
