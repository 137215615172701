import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material";
import MainCard from "../../components/MainCard";
import EnhancedTableHead from "../../components/EnhancedTableHead";
import moment from "moment";

const headCells = [
  {
    id: "summary",
    label: "Summary",
  },
  {
    id: "detail",
    label: "Details",
  },
];

const SummaryTable = ({ iteration }) => {
  return (
    <MainCard>
      <TableContainer>
        <Table aria-labelledby="tableTitle" size="small">
          <EnhancedTableHead sortable={false} headCells={headCells} />
          <TableBody>
            <TableRow hover tabIndex={-1}>
              <TableCell
                sx={{
                  padding: "6px 8px",
                }}
              >
                <Typography variant="subtitle1">Iteration Id</Typography>
              </TableCell>
              <TableCell
                sx={{
                  padding: "6px 8px",
                }}
              >
                <Typography>{iteration?.iterationId}</Typography>
              </TableCell>
            </TableRow>
            <TableRow hover tabIndex={-1}>
              <TableCell
                sx={{
                  padding: "6px 8px",
                }}
              >
                <Typography variant="subtitle1">Test Type</Typography>
              </TableCell>
              <TableCell
                sx={{
                  padding: "6px 8px",
                }}
              >
                <Typography>{iteration?.testType}</Typography>
              </TableCell>
            </TableRow>
            <TableRow hover tabIndex={-1}>
              <TableCell
                sx={{
                  padding: "6px 8px",
                }}
              >
                <Typography variant="subtitle1">Browser Used</Typography>
              </TableCell>
              <TableCell
                sx={{
                  padding: "6px 8px",
                }}
              >
                <Typography>{iteration?.browserUsed}</Typography>
              </TableCell>
            </TableRow>
            <TableRow hover tabIndex={-1}>
              <TableCell
                sx={{
                  padding: "6px 8px",
                }}
              >
                <Typography variant="subtitle1">Browser Version</Typography>
              </TableCell>
              <TableCell
                sx={{
                  padding: "6px 8px",
                }}
              >
                <Typography>{iteration?.browserVersion}</Typography>
              </TableCell>
            </TableRow>
            <TableRow hover tabIndex={-1}>
              <TableCell
                sx={{
                  padding: "6px 8px",
                }}
              >
                <Typography variant="subtitle1">
                  Execution Start Time
                </Typography>
              </TableCell>
              <TableCell
                sx={{
                  padding: "6px 8px",
                }}
              >
                <Typography>
                  {iteration
                    ? moment(iteration?.executionStartTime).format(
                        "MMMM Do YYYY, h:mm:ss a"
                      )
                    : "-"}
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow hover tabIndex={-1}>
              <TableCell
                sx={{
                  padding: "6px 8px",
                }}
              >
                <Typography variant="subtitle1">Execution End Time</Typography>
              </TableCell>
              <TableCell
                sx={{
                  padding: "6px 8px",
                }}
              >
                <Typography>
                  {iteration
                    ? moment(iteration?.executionEndTime)?.format(
                        "MMMM Do YYYY, h:mm:ss a"
                      )
                    : "-"}
                </Typography>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </MainCard>
  );
};

export default SummaryTable;
