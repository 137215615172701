import { ButtonBase } from "@mui/material";
import React from "react";
import Logo from "../../components/Logo";
import { useNavigate } from "react-router-dom";

const LogoSection = () => {
  const Navigate = useNavigate();
  return (
    <ButtonBase disableRipple onClick={() => Navigate("/")}>
      <Logo />
    </ButtonBase>
  );
};

export default LogoSection;
