import { lazy } from "react";

import MainLayout from "../mainLayout";
import Loadable from "../components/Loadable";
import ProtectedRoutes from "./ProtectedRoutes";
import { useAppContext } from "../context/AppContext";
import { Navigate } from "react-router-dom";

const UserLoader = Loadable(lazy(() => import("../views/User")));
const ProjectLoader = Loadable(lazy(() => import("../views/Project")));
const IterationLoader = Loadable(lazy(() => import("../views/Iterations")));
const TestCaseLoader = Loadable(lazy(() => import("../views/TestCases")));
const OrganizationLoader = Loadable(
  lazy(() => import("../views/Organization"))
);
const DocumentationLoader = Loadable(
  lazy(() => import("../views/Documentation"))
);

const UserRoutes = () => {
  const { loggedInUser } = useAppContext();
  return {
    path: "/",
    element: (
      <ProtectedRoutes>
        <MainLayout />
      </ProtectedRoutes>
    ),
    caseSensitive: false,
    children: [
      {
        index: true,
        element: (
          <Navigate
            to={
              loggedInUser?.role === "org-member"
                ? `/projects/${loggedInUser?.organizationId}/${loggedInUser?._id}`
                : loggedInUser?.role === "super-admin"
                ? "/organizations"
                : `/users/${loggedInUser?.organizationId}`
            }
            replace
          />
        ),
      },
      {
        path: "organizations",
        element:
          loggedInUser?.role === "super-admin" ? (
            <OrganizationLoader />
          ) : loggedInUser?.role === "org-member" ? (
            <Navigate
              to={`/projects/${loggedInUser?.organizationId}/${loggedInUser?._id}`}
              replace
            />
          ) : (
            <Navigate to={`/users/${loggedInUser?.organizationId}`} replace />
          ),
      },
      { path: "projects/:organizationId", element: <ProjectLoader /> },
      { path: "projects/:organizationId/:userId", element: <ProjectLoader /> },
      { path: "documentation", element: <DocumentationLoader /> },
      {
        path: "users/:organizationId",
        element:
          loggedInUser?.role === "org-member" ? (
            <Navigate
              to={`/projects/${loggedInUser?.organizationId}/${loggedInUser?._id}`}
              replace
            />
          ) : (
            <UserLoader />
          ),
      },
      {
        path: "iterations/:organizationId/:userId/:projectId",
        element: <IterationLoader />,
      },
      {
        path: "testcases/:organizationId/:userId/:projectId/:iterationId",
        element: <TestCaseLoader />,
      },
    ],
  };
};

export default UserRoutes;
