import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
// material-ui
import { useTheme } from "@mui/material/styles";
import { Divider, Grid, Stack, Typography, useMediaQuery } from "@mui/material";

// project imports
import Logo from "../../components/Logo";
import LoginForm from "./LoginForm";
import { useAppContext } from "../../context/AppContext";

const Login = () => {
  const theme = useTheme();
  const matchDownSM = useMediaQuery(theme.breakpoints.down("md"));
  const navigate = useNavigate();
  const { loggedInUser } = useAppContext();

  useEffect(() => {
    if (loggedInUser) {
      navigate("/");
    }
  }, [loggedInUser, navigate]);

  return (
    <Grid container spacing={2} alignItems="center" justifyContent="center">
      <Grid item sx={{ mb: 3 }}>
        <Logo width={240} />
      </Grid>
      <Grid item xs={12}>
        <Grid
          container
          direction={matchDownSM ? "column-reverse" : "row"}
          alignItems="center"
          justifyContent="center"
        >
          <Grid item>
            <Stack alignItems="center" justifyContent="center" spacing={1}>
              <Typography
                color={theme.palette.secondary.main}
                gutterBottom
                variant={matchDownSM ? "h3" : "h2"}
              >
                Hi, Welcome Back
              </Typography>
              <Typography
                variant="subtitle1"
                fontSize="16px"
                textAlign={matchDownSM ? "center" : "inherit"}
              >
                Enter your credentials to continue
              </Typography>
            </Stack>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={12}>
        <LoginForm />
      </Grid>
    </Grid>
  );
};

export default Login;
