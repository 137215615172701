import AssignmentIcon from "@mui/icons-material/Assignment";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import BusinessIcon from "@mui/icons-material/Business";
import Groups2Icon from "@mui/icons-material/Groups2";

const MenuItems = (organizationId, userId) => {
  return [
    {
      id: "organizations",
      title: "Organizations",
      url: `/organizations`,
      icon: BusinessIcon,
      external: false,
      target: false,
    },
    {
      id: "users",
      title: "Users",
      url: `/users/${organizationId}`,
      icon: Groups2Icon,
      external: false,
      target: false,
    },
    {
      id: "projects",
      title: "Projects",
      url:
        organizationId === "all"
          ? `/projects/all`
          : `/projects/${organizationId}/${userId}`,
      icon: AccountTreeIcon,
      external: false,
      target: false,
    },
    {
      id: "update-profile",
      title: "Update Profile",
      url: "/update-profile",
      icon: ManageAccountsIcon,
      external: false,
      target: false,
    },
    {
      id: "documentation",
      title: "Documentation",
      url: "/documentation",
      icon: AssignmentIcon,
      external: false,
      target: false,
    },
    {
      id: "contactUs",
      title: "Contact Us",
      url: "/user-profile",
      icon: AlternateEmailIcon,
      external: false,
      target: false,
    },
  ];
};

export default MenuItems;
