import { Grid } from "@mui/material";
import MainCard from "../../components/MainCard";
import { useEffect } from "react";
import { useAppContext } from "../../context/AppContext";

import ProjectTable from "./ProjectTable";
import { useParams } from "react-router-dom";

const Project = () => {
  const {
    projects,
    totalProjects,
    getAllProjects,
    clearProject,
    loggedInUser,
    user,
    getUser,
    organization,
    getOrganization,
    clearProjects,
  } = useAppContext();
  const { organizationId, userId } = useParams();

  useEffect(() => {
    async function fetchProjects() {
      if (
        loggedInUser.role !== "org-member" &&
        organizationId !== "all" &&
        !organization
      )
        await getOrganization(organizationId);
      if (userId !== loggedInUser._id && organizationId !== "all" && !user)
        await getUser(userId);
      await getAllProjects({ userId });
    }
    clearProjects();
    fetchProjects();
    clearProject();
  }, []);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <MainCard
          title={user ? `${user?.firstName}'s Projects` : "Your Projects"}
          content={false}
        />
      </Grid>
      <Grid item xs={12}>
        <ProjectTable totalProjects={totalProjects} projects={projects || []} />
      </Grid>
    </Grid>
  );
};

export default Project;
