import React, { forwardRef } from "react";
import MenuItems from "./MenuItems";
import {
  Avatar,
  Box,
  Divider,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { Link, useLocation, useParams } from "react-router-dom";
import SupervisedUserCircleIcon from "@mui/icons-material/SupervisedUserCircle";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { useAppContext } from "../../../context/AppContext";

const MenuList = () => {
  const {
    loggedInUser,
    clearOrganizations,
    clearUsers,
    clearProjects,
    getAllOrganizations,
    getAllProjects,
    getAllUsers,
  } = useAppContext();
  let { organizationId } = useParams();

  if (loggedInUser?.role !== "super-admin")
    organizationId = loggedInUser?.organizationId;
  else organizationId = "all";

  const menuItems = MenuItems(organizationId, loggedInUser?._id);
  const theme = useTheme();

  const { pathname } = useLocation();
  const AccIconSx = {
    width: "32px",
    height: "32px",
  };
  const items = menuItems
    .filter((item) => {
      if (item.id === "organizations") {
        if (loggedInUser?.role === "super-admin") return true;
        else return false;
      } else if (item.id === "users") {
        if (loggedInUser?.role !== "org-member") return true;
        else return false;
      } else return true;
    })
    .map((item) => {
      let isSelected = false;
      if (pathname.includes(item.id) || pathname === item.url)
        isSelected = true;
      else isSelected = false;

      let itemTarget = "_self";
      if (item.target) {
        itemTarget = "_blank";
      }

      let listItemProps = {
        component: forwardRef((props, ref) => (
          <Link ref={ref} {...props} to={item.url} target={itemTarget} />
        )),
      };
      if (item.external) {
        listItemProps = { component: "a", href: item.url, target: itemTarget };
      }

      return (
        <React.Fragment key={item.id}>
          <ListItemButton
            {...listItemProps}
            disabled={item.disabled}
            sx={{
              borderRadius: "8px",
              alignItems: "flex-start",
              backgroundColor: "inherit",
              pl: "24px",
              mx: 1,
              my: 0.7,
              py: "5px"
            }}
            selected={isSelected}
            onClick={async () => {
              if (item.id === "organizations") {
                await clearOrganizations();
                await getAllOrganizations();
              } else if (item.id === "users") {
                await clearUsers();
                await getAllUsers(organizationId);
              } else if (item.id === "projects") {
                await clearProjects();
                if (loggedInUser?.role === "super-admin")
                  await getAllProjects({});
                else await getAllProjects({ userId: loggedInUser?._id });
              }
            }}
          >
            <ListItemIcon sx={{ my: "auto", minWidth: !item.icon ? 18 : 36 }}>
              {<item.icon stroke={1.5} size="1.3rem" />}
            </ListItemIcon>
            <ListItemText
              primary={
                <Typography
                  variant={isSelected ? "h4" : "body1"}
                  color="inherit"
                >
                  {item.title}
                </Typography>
              }
            />
          </ListItemButton>
        </React.Fragment>
      );
    });

  return (
    <Stack direction="column" justifyContent={"space-between"} height="100%">
      <List
        sx={{
          pt: 0,
        }}
      >
        <Divider sx={{ mb: 1.25 }} />
        <Stack
          direction="row"
          sx={{
            ml: 1,
            px: 1.3,
            py: 0.7,
          }}
          justifyContent="space-between"
        >
          <Avatar
            sx={{
              height: "50px",
              width: "50px",
              bgcolor: theme.palette.primary.light,
              color: theme.palette.primary.main,
              border: "2px solid",
            }}
          >
            {loggedInUser?.role === "super-admin" ? (
              <AdminPanelSettingsIcon sx={AccIconSx} />
            ) : loggedInUser?.role === "org-admin" ? (
              <SupervisedUserCircleIcon sx={AccIconSx} />
            ) : (
              <AccountCircleIcon sx={AccIconSx} />
            )}
          </Avatar>
          <Stack spacing={0.5} justifyContent="center">
            <Box display="block" width="150px">
              <Typography variant="h3" noWrap>
                {loggedInUser?.firstName} {loggedInUser?.lastName}
              </Typography>
            </Box>
            <Box display="block" width="150px">
              <Typography
                variant="h4"
                noWrap
                sx={{
                  color: theme.palette.grey[500],
                }}
              >
                {loggedInUser?.organizationName}
              </Typography>
            </Box>
          </Stack>
        </Stack>

        <Divider sx={{ mt: 1.5, mb: 0.5 }} />
        {items}
      </List>
      <Box>
        <Typography align="center" fontWeight={"bold"} variant="subtitle2">
          ©2024 PrimeQA Solutions
        </Typography>
        <Typography align="center" variant="subtitle2">
          All Rights Reserved.
        </Typography>
      </Box>
    </Stack>
  );
};

export default MenuList;
