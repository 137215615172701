import { Typography } from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import HomeTwoToneIcon from "@mui/icons-material/HomeTwoTone";
import { useTheme } from "@mui/material/styles";
import MuiBreadcrumbs from "@mui/material/Breadcrumbs";
import { useAppContext } from "../context/AppContext";

const DynamicBreadCrumb = () => {
  const theme = useTheme();
  const location = useLocation();
  const { loggedInUser } = useAppContext();

  const linkSX = {
    display: "flex",
    color: "grey.900",
    textDecoration: "none",
    alignContent: "center",
    alignItems: "center",
  };

  const mapLinkToBreadcrumb = (link) => {
    switch (link) {
      case "projects":
        return "Projects";
      case "iterations":
        return "Iterations";
      case "testcases":
        return "Dashboard";
      case "users":
        return "Users";
      case "organizations":
        return "Organizations";
      case "documentation":
        return "Documentation";
      default:
        return "";
    }
  };

  const pathnames = location.pathname.split("/").filter((x) => x);

  let mainContent = [];
  let itemContent = (
    <Typography
      key={`breadcrumb-item-${pathnames[0]}`}
      variant="subtitle1"
      sx={{
        display: "flex",
        textDecoration: "none",
        alignContent: "baseline",
        alignItems: "baseline",
        color: "grey.500",
      }}
    >
      {mapLinkToBreadcrumb(pathnames[0])}
    </Typography>
  );

  if (pathnames.length > 0) {
    switch (pathnames[0]) {
      case "users":
        if (pathnames[1] === "all") mainContent = [];
        else if (loggedInUser.role === "super-admin")
          mainContent = [
            <Typography
              component={Link}
              to={`/organizations`}
              variant="subtitle1"
              sx={linkSX}
              key="organizations"
            >
              Organizations
            </Typography>,
          ];
        break;
      case "projects":
        if (pathnames[1] === "all") {
          mainContent = [];
          break;
        }
        if (loggedInUser.role === "super-admin")
          mainContent = [
            <Typography
              component={Link}
              to={`/organizations`}
              variant="subtitle1"
              sx={linkSX}
              key="organizations"
            >
              Organizations
            </Typography>,
          ];
        mainContent.push(
          <Typography
            component={Link}
            to={`/users/${pathnames[1]}`}
            variant="subtitle1"
            sx={linkSX}
            key="users"
          >
            Users
          </Typography>
        );
        break;
      case "iterations":
        if (loggedInUser.role === "super-admin")
          mainContent = [
            <Typography
              component={Link}
              to={`/organizations`}
              variant="subtitle1"
              sx={linkSX}
              key="organizations"
            >
              Organizations
            </Typography>,
          ];
        mainContent.push([
          <Typography
            component={Link}
            to={`/users/${pathnames[1]}`}
            variant="subtitle1"
            sx={linkSX}
            key="users"
          >
            Users
          </Typography>,
          <Typography
            component={Link}
            to={`/projects/${pathnames[1]}/${pathnames[2]}`}
            variant="subtitle1"
            sx={linkSX}
            key="projects"
          >
            Projects
          </Typography>,
        ]);
        break;
      case "testcases":
        if (loggedInUser.role === "super-admin")
          mainContent = [
            <Typography
              component={Link}
              to={`/organizations`}
              variant="subtitle1"
              sx={linkSX}
              key="organizations"
            >
              Organizations
            </Typography>,
          ];
        mainContent.push([
          <Typography
            component={Link}
            to={`/users/${pathnames[1]}`}
            variant="subtitle1"
            sx={linkSX}
            key="users"
          >
            Users
          </Typography>,
          <Typography
            component={Link}
            to={`/projects/${pathnames[1]}/${pathnames[2]}`}
            variant="subtitle1"
            sx={linkSX}
            key="projects"
          >
            Projects
          </Typography>,
          <Typography
            component={Link}
            to={`/iterations/${pathnames[1]}/${pathnames[2]}/${pathnames[3]}`}
            variant="subtitle1"
            sx={linkSX}
            key="iterations"
          >
            Iterations
          </Typography>,
        ]);
        break;
      default:
        mainContent = [];
    }
  }

  // if (pathnames.length > 0) {
  //   if (pathnames[0] !== "users")
  //     switch (pathnames.length) {
  //       case 3:
  //         mainContent = (
  //           <Typography
  //             component={Link}
  //             to={`/projects/${pathnames[1]}`}
  //             variant="subtitle1"
  //             sx={linkSX}
  //             key="projects"
  //           >
  //             {user?.firstName ? `${user.firstName}'s Projects` : "Projects"}
  //           </Typography>
  //         );
  //         break;
  //       case 4:
  //         mainContent = [
  //           <Typography
  //             component={Link}
  //             to={`/projects/${pathnames[1]}`}
  //             variant="subtitle1"
  //             sx={linkSX}
  //             key="projects"
  //           >
  //             {user?.firstName ? `${user.firstName}'s Projects` : "Projects"}
  //           </Typography>,
  //           <Typography
  //             component={Link}
  //             to={`/iterations/${pathnames
  //               .slice(1, pathnames.length - 1)
  //               .join("/")}`}
  //             variant="subtitle1"
  //             sx={linkSX}
  //             key="iterations"
  //           >
  //             {project && project.projectName
  //               ? `${project.projectName}'s Iterations`
  //               : "Iterations"}
  //           </Typography>,
  //         ];
  //         break;
  //       default:
  //         break;
  //     }
  // }

  return (
    <MuiBreadcrumbs
      sx={{
        "& .MuiBreadcrumbs-separator": {
          width: 16,
        },
      }}
      aria-label="breadcrumb"
    >
      <Typography
        component={Link}
        to="/"
        color="inherit"
        variant="subtitle1"
        sx={linkSX}
        key="home"
      >
        <HomeTwoToneIcon
          sx={{
            marginRight: theme.spacing(0.75),
            marginTop: `-${theme.spacing(0.25)}`,
            color: theme.palette.primary.main,
          }}
        />
      </Typography>
      {mainContent}
      {itemContent}
    </MuiBreadcrumbs>
  );
};

export default DynamicBreadCrumb;
