import {
  Box,
  Collapse,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import MainCard from "../../components/MainCard";
import { useMemo, useState } from "react";
import EnhancedTableHead from "../../components/EnhancedTableHead";
import { useTheme } from "@emotion/react";
import ImageIcon from "@mui/icons-material/Image";
import LightBox from "../../components/LightBox";

const headCells = [
  {
    id: "testCaseId",
    label: "ID",
    align: "left",
    sortable: true,
  },
  {
    id: "summary",
    label: "Summary",
    align: "left",
    sortable: false,
  },
  {
    id: "result",
    label: "Result",
    align: "left",
    sortable: true,
  },
  {
    id: "executionTime",
    label: "Execution Time (sec)",
    align: "center",
    sortable: true,
  },
  {
    id: "action",
    label: "Action",
    align: "center",
    sortable: false,
  },
];

const TestCasesTable = ({ testCases }) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const theme = useTheme();

  const [isImageModalOpen, setIsImageModalOpen] = useState(false);
  const [modalImageUrl, setModalImageUrl] = useState("");

  const closeLightBox = () => {
    setIsImageModalOpen(false);
    setModalImageUrl("");
  };

  const visibleRows = useMemo(
    () => testCases.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage),
    [page, rowsPerPage, testCases]
  );

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - testCases.length) : 0;

  return (
    <MainCard>
      {testCases.length > 0 ? (
        <Box sx={{ width: "100%" }}>
          <Paper sx={{ width: "100%", mb: 2 }}>
            <TableContainer>
              <Table
                sx={{ minWidth: 750 }}
                aria-labelledby="tableTitle"
                size="small"
              >
                <EnhancedTableHead sortable={false} headCells={headCells} />
                <TableBody>
                  {visibleRows.map((testCase, index) => {
                    return (
                      <CollapsibleRow
                        testCase={testCase}
                        key={index}
                        theme={theme}
                        setIsImageModalOpen={setIsImageModalOpen}
                        setModalImageUrl={setModalImageUrl}
                      />
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow
                      style={{
                        height: 53 * emptyRows,
                      }}
                    >
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 15, 30]}
              component="div"
              count={testCases.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
            {isImageModalOpen && (
              <LightBox
                image={modalImageUrl}
                title="Failure Image"
                allowRotate={false}
                onClose={closeLightBox}
                doubleClickZoom={0}
              />
            )}
          </Paper>
        </Box>
      ) : (
        <Typography variant="body1" align="center">
          No Records found.
        </Typography>
      )}
    </MainCard>
  );
};

const CollapsibleRow = ({
  testCase,
  theme,
  setModalImageUrl,
  setIsImageModalOpen,
}) => {
  const openImage = (event, testCase) => {
    setModalImageUrl(testCase.screenshot);
    setIsImageModalOpen(true);
  };
  const [open, setOpen] = useState(false);
  return (
    <>
      <TableRow
        hover
        onClick={(event) => {
          testCase.failureTrace && setOpen(!open);
        }}
        tabIndex={-1}
        sx={{ cursor: "pointer" }}
      >
        <TableCell>{testCase.testCaseId}</TableCell>
        <TableCell>{testCase.testCaseSummary}</TableCell>
        <TableCell>
          <Typography
            sx={{
              color:
                testCase.result === "Pass"
                  ? theme.palette.success.main
                  : testCase.result === "Fail"
                  ? theme.palette.error.main
                  : theme.palette.orange.main,
              fontWeight: "bold",
            }}
          >
            {testCase.result}
          </Typography>
        </TableCell>
        <TableCell align="center">{testCase.executionTime}</TableCell>
        <TableCell align="center">
          <Tooltip title="Failure Screenshot">
            <IconButton
              sx={{
                visibility:
                  testCase.screenshot && testCase.screenshot.trim() !== ""
                    ? "visible"
                    : "hidden",
              }}
              size="large"
              color="primary"
              tabIndex={-1}
              onClick={(event) => openImage(event, testCase)}
            >
              <ImageIcon color="primary" />
            </IconButton>
          </Tooltip>
        </TableCell>
      </TableRow>
      {testCase.failureTrace && (
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={4}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box sx={{ margin: 1 }}>
                <Typography variant="h4" gutterBottom component="div">
                  Failure Cause
                </Typography>
                <code>{testCase.failureTrace}</code>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      )}
    </>
  );
};

export default TestCasesTable;
