export const DISPLAY_ALERT = "DISPLAY_ALERT";
export const CLEAR_ALERT = "CLEAR_ALERT";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const UPDATE_USER_SUCCESS = "UPDATE_USER";
export const SET_LOADING = "SET_LOADING";
export const CLEAR_LOADING = "CLEAR_LOADING";

export const GET_PROJECTS_SUCCESS = "GET_PROJECTS_SUCCESS";
export const CLEAR_PROJECTS = "CLEAR_PROJECTS";
export const SET_PROJECT = "SET_PROJECT";
export const CLEAR_PROJECT = "CLEAR_PROJECT";
export const GET_ITERATIONS_SUCCESS = "GET_ITERATIONS_SUCCESS";
export const CLEAR_ITERATIONS = "CLEAR_ITERATIONS";
export const SET_ITERATION = "SET_ITERATION";
export const CLEAR_ITERATION = "CLEAR_ITERATION";
export const GET_TEST_CASES_SUCCESS = "GET_TEST_CASES_SUCCESS";
export const CLEAR_TEST_CASES = "CLEAR_TEST_CASES";
export const GET_USERS_SUCCESS = "GET_USERS_SUCCESS";
export const CLEAR_USERS = "CLEAR_USERS";
export const SET_USER = "SET_USER";
export const CLEAR_USER = "CLEAR_USER";
export const PASSWORD_CHANGE_SUCCESS = "PASSWORD_CHANGE_SUCCESS";

export const GET_ORGANIZATIONS_SUCCESS = "GET_ORGANIZATIONS_SUCCESS";
export const CLEAR_ORGANIZATIONS = "CLEAR_ORGANIZATIONS";
export const SET_ORGANIZATION = "SET_ORGANIZATION";
export const CLEAR_ORGANIZATION = "CLEAR_ORGANIZATION";
export const GET_ORGANIZATION_MEMBERS_SUCCESS =
  "GET_ORGANIZATION_MEMBERS_SUCCESS";
export const CLEAR_ORGANIZATION_MEMBERS = "CLEAR_ORGANIZATION_MEMBERS";
