import {
  CssBaseline,
  StyledEngineProvider,
  ThemeProvider,
} from "@mui/material";

import defaultTheme from "./themes";
import NavigationScroll from "./components/NavigationScroll";
import Routes from "./routes";
import AlertSection from "./components/AlertSection";
import LoadingModal from "./components/LoadingModal";

function App() {
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={defaultTheme()}>
        <CssBaseline />
        <NavigationScroll>
          <Routes />
        </NavigationScroll>
        <AlertSection />
        <LoadingModal />
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

export default App;
