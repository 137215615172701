import React from "react";
import { Typography, Button, Container, Grid } from "@mui/material";
import Logo from "../components/Logo";

const NotFound = () => {
  return (
    <Container maxWidth="sm">
      <Grid
        container
        direction="column"
        justifyContent="space-evenly"
        alignItems="center"
        style={{ height: "100vh" }}
        spacing={2}
      >
        <Grid item>
          <Logo width={400} />
        </Grid>
        <Grid item>
          <Typography variant="h1" style={{ fontSize: 36, color: "#f44336" }}>
            404 Not Found
          </Typography>
        </Grid>
        <Grid item>
          <Typography
            variant="subtitle1"
            style={{ fontSize: 18, color: "#777" }}
          >
            Oops! Looks like you took a wrong turn.
          </Typography>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            href="/"
            style={{ padding: "12px 24px", fontSize: 16, color: "#fff" }}
          >
            Go Back Home
          </Button>
        </Grid>
      </Grid>
    </Container>
  );
};

export default NotFound;
