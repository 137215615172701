import { useEffect } from "react";
import { useAppContext } from "../context/AppContext";
import { useNavigate } from "react-router-dom";

const ProtectedRoutes = ({ children }) => {
  const { loggedInUser } = useAppContext();
  const navigate = useNavigate();
  useEffect(() => {
    if (!loggedInUser) {
      navigate("/login");
    } else if (loggedInUser?.newPassword) {
      navigate("/change-password");
    }
  }, [navigate, loggedInUser]);

  return children;
};

export default ProtectedRoutes;
