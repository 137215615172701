import React from "react";
import { styled, useTheme } from "@mui/material/styles";
import {
  Avatar,
  Box,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from "@mui/material";

import MainCard from "../../components/MainCard";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CategoryIcon from "@mui/icons-material/Category";
import DoNotDisturbOnIcon from "@mui/icons-material/DoNotDisturbOn";
// import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
// import AssignmentLateIcon from "@mui/icons-material/AssignmentLate";
// import AssignmentIcon from "@mui/icons-material/Assignment";

const CardType = {
  total: 0,
  success: 1,
  fail: 2,
  skip: 3,
};

const ResultCard = ({ isLoading, cardType, count }) => {
  const CardWrapper = styled(MainCard)(({ theme }) => ({
    overflow: "hidden",
    position: "relative",
    "&:after": {
      content: '""',
      position: "absolute",
      width: 210,
      height: 210,
      background: `linear-gradient(210.04deg, ${
        cardType === CardType.total
          ? theme.palette.primary[200]
          : cardType === CardType.success
          ? theme.palette.success[200]
          : cardType === CardType.fail
          ? theme.palette.error.light
          : theme.palette.orange.main
      } -50.94%, rgba(144, 202, 249, 0) 83.49%)`,
      borderRadius: "50%",
      top: -30,
      right: -180,
    },
    "&:before": {
      content: '""',
      position: "absolute",
      width: 210,
      height: 210,
      background: `linear-gradient(140.9deg, ${
        cardType === CardType.total
          ? theme.palette.primary[200]
          : cardType === CardType.success
          ? theme.palette.success[200]
          : cardType === CardType.fail
          ? theme.palette.error.light
          : theme.palette.orange.main
      } -14.02%, rgba(144, 202, 249, 0) 77.58%)`,
      borderRadius: "50%",
      top: -160,
      right: -130,
    },
  }));
  const theme = useTheme();

  return (
    <>
      {isLoading ? (
        //TODO: Implement skeletons for cards 
        <div></div>
      ) : (
        <CardWrapper border={false} content={false}>
          <Box sx={{ p: 2 }}>
            <List sx={{ py: 0 }}>
              <ListItem alignItems="center" disableGutters sx={{ py: 0 }}>
                <ListItemAvatar>
                  <Avatar
                    variant="rounded"
                    sx={{
                      ...theme.typography.commonAvatar,
                      ...theme.typography.largeAvatar,
                      backgroundColor:
                        cardType === CardType.total
                          ? theme.palette.primary.main
                          : cardType === CardType.success
                          ? theme.palette.success.dark
                          : cardType === CardType.fail
                          ? theme.palette.error.dark
                          : theme.palette.orange.main,
                      color: "#fff",
                    }}
                  >
                    {cardType === CardType.total ? (
                      <CategoryIcon />
                    ) : cardType === CardType.success ? (
                      <CheckCircleIcon />
                    ) : cardType === CardType.fail ? (
                      <CancelIcon />
                    ) : (
                      <DoNotDisturbOnIcon />
                    )}
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  sx={{
                    py: 0,
                    mt: 0.45,
                    mb: 0.45,
                  }}
                  primary={
                    <Typography
                      variant="h3"
                      sx={{
                        color:
                          cardType === CardType.total
                            ? theme.palette.primary.main
                            : cardType === CardType.success
                            ? theme.palette.success.main
                            : cardType === CardType.fail
                            ? theme.palette.error.dark
                            : theme.palette.orange.main,
                      }}
                    >
                      {count}{" "}
                      {cardType === CardType.total
                        ? "Total Test Cases"
                        : cardType === CardType.success
                        ? "Test Cases Passed"
                        : cardType === CardType.fail
                        ? "Test Cases Failed"
                        : "Test Cases Skipped"}
                    </Typography>
                  }
                />
              </ListItem>
            </List>
          </Box>
        </CardWrapper>
      )}
    </>
  );
};

export { ResultCard, CardType };
