import { forwardRef } from "react";

import { useTheme } from "@mui/material/styles";
import {
  Box,
  Card,
  CardContent,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import DynamicBreadCrumb from "./DynamicBreadCrumb";

const MainCard = forwardRef(
  (
    {
      border = true,
      boxShadow,
      children,
      content = true,
      contentClass = "",
      contentSX = {},
      darkTitle,
      breadcrumbs = true,
      shadow,
      sx = {},
      title,
      ...others
    },
    ref
  ) => {
    const theme = useTheme();
    return (
      <Card
        ref={ref}
        {...others}
        sx={{
          border: border ? "1px solid" : "none",
          borderColor: theme.palette.primary[200] + 25,
          ":hover": {
            boxShadow: boxShadow
              ? shadow || "0 2px 14px 0 rgb(32 40 45 / 8%)"
              : "inherit",
          },
          ...sx,
        }}
      >
        {/* card header and action */}
        {title && (
          <Box sx={{ padding: "20px" }}>
            <Grid container spacing={1} justifyContent={"space-between"}>
              <Grid item>
                <Typography variant="h3">{title}</Typography>
              </Grid>
              <Grid item>{breadcrumbs && <DynamicBreadCrumb />}</Grid>
            </Grid>
          </Box>
        )}

        {/* content & header divider */}
        {title && children && <Divider />}

        {/* card content */}
        {content && (
          <CardContent sx={contentSX} className={contentClass}>
            {children}
          </CardContent>
        )}
        {!content && children}
      </Card>
    );
  }
);

export default MainCard;
