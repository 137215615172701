import {
  Autocomplete,
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormHelperText,
  InputLabel,
  OutlinedInput,
  Paper,
  TextField,
} from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import AnimateButton from "../../components/AnimateButton";
import SaveIcon from "@mui/icons-material/Save";
import { useAppContext } from "../../context/AppContext";

const EditProjectModal = ({
  modalOpen,
  handleClose,
  project,
  handleUpdateProject,
}) => {
  const { organization, user, loggedInUser } = useAppContext();

  const fixedUserEmail = useMemo(() => {
    if (user) {
      return [
        {
          email: user ? user.email : "",
          _id: user ? user._id : "",
        },
      ];
    } else {
      return [
        {
          email: loggedInUser?.email,
          _id: loggedInUser?._id,
        },
      ];
    }
  }, [loggedInUser?._id, loggedInUser?.email, user]);

  useEffect(() => {
    if (modalOpen) {
      setProjectName(project ? project.projectName : "");
      setProjectMembers(project ? project.projectMembers : [...fixedUserEmail]);
      setErrors({
        projectName: "",
        projectMembers: "",
        submit: "",
      });
    } else {
      setProjectName("");
      setProjectMembers([...fixedUserEmail]);
    }
  }, [fixedUserEmail, modalOpen, project]);

  const [projectName, setProjectName] = useState(
    project ? project.projectName : ""
  );
  const [projectMembers, setProjectMembers] = useState(
    project ? project.projectMembers : [...fixedUserEmail]
  );
  const [errors, setErrors] = useState({
    projectName: "",
    projectMembers: "",
    submit: "",
  });

  const filteredOrgMembers = useMemo(() => {
    const members = organization?.organizationMembers || [];
    return members.filter(
      (member) =>
        !projectMembers.some((selected) => selected.email === member.email)
    );
  }, [organization?.organizationMembers, projectMembers]);

  return (
    <Dialog open={modalOpen} onClose={handleClose}>
      <DialogTitle variant="h3">
        {project ? "Edit Project" : "Add New Project"}
      </DialogTitle>
      <DialogContent>
        <Box sx={{ minWidth: "300px", width: "100%" }}>
          <Paper sx={{ width: "100%", mb: 2 }}>
            <form noValidate>
              <FormControl
                fullWidth
                error={Boolean(errors.projectName)}
                sx={{
                  my: 1,
                }}
              >
                <InputLabel htmlFor="outlined-adornment-project-name">
                  Project Name
                </InputLabel>
                <OutlinedInput
                  id="outlined-adornment-project-name"
                  value={projectName}
                  name="projectName"
                  onBlur={(event) => {
                    if (projectName.trim() === "")
                      setErrors({
                        ...errors,
                        projectName: "Project name is required",
                      });
                    else
                      setErrors({
                        ...errors,
                        projectName: "",
                      });
                  }}
                  onChange={(event) => {
                    setProjectName(event.target.value);
                  }}
                  label="Project Name"
                  inputProps={{}}
                />
                {errors.projectName && (
                  <FormHelperText
                    error
                    id="standard-weight-helper-text-project-name"
                  >
                    {errors.projectName}
                  </FormHelperText>
                )}
              </FormControl>

              <FormControl
                fullWidth
                error={Boolean(errors.projectMembers)}
                sx={{
                  my: 1,
                }}
              >
                <Autocomplete
                  multiple
                  id="outlined-adornment-projectMembers"
                  value={projectMembers}
                  isOptionEqualToValue={(option, value) =>
                    option.email === value.email
                  }
                  onChange={(event, value) => {
                    if (
                      fixedUserEmail.length > 0 &&
                      value.findIndex(
                        (option) => option.email === fixedUserEmail[0].email
                      ) === -1
                    ) {
                      // Ensure fixedUserEmail is always selected
                      value = [fixedUserEmail[0], ...value];
                    }
                    setProjectMembers(value);
                  }}
                  options={filteredOrgMembers || []}
                  getOptionLabel={(option) => option.email}
                  renderTags={(tagValue, getTagProps) =>
                    tagValue.map((option, index) => (
                      <Chip
                        label={option.email}
                        {...getTagProps({ index })}
                        disabled={
                          fixedUserEmail.indexOf(option) !== -1 || option.owner
                        }
                      />
                    ))
                  }
                  renderInput={(params) => (
                    <TextField {...params} label="Select Project Members" />
                  )}
                  onBlur={(event) => {
                    if (projectMembers.length === 0)
                      setErrors({
                        ...errors,
                        projectMembers: "Select at least one project member",
                      });
                    else
                      setErrors({
                        ...errors,
                        projectMembers: "",
                      });
                  }}
                />
                {errors.projectMembers && (
                  <FormHelperText
                    error
                    id="standard-weight-helper-text-projectMembers"
                  >
                    {errors.projectMembers}
                  </FormHelperText>
                )}
              </FormControl>

              {errors.submit && (
                <Box sx={{ mt: 2 }}>
                  <Divider />
                  <FormHelperText error style={{ fontSize: "14px" }}>
                    {errors.submit}
                  </FormHelperText>
                </Box>
              )}
            </form>
          </Paper>
        </Box>

        <Divider />
      </DialogContent>
      <DialogActions>
        <AnimateButton>
          <Button
            variant="contained"
            disabled={Boolean(
              projectName.trim() === "" || projectMembers.length === 0
            )}
            onClick={(event) => {
              handleClose();
              handleUpdateProject({ projectName, projectMembers });
            }}
            startIcon={<SaveIcon />}
          >
            Save
          </Button>
        </AnimateButton>
        <AnimateButton>
          <Button variant="contained" onClick={handleClose}>
            Close
          </Button>
        </AnimateButton>
      </DialogActions>
    </Dialog>
  );
};

export default EditProjectModal;
