import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import MainCard from "../components/MainCard";
import { useEffect, useMemo, useState } from "react";
import { getComparator, stableSort } from "../utils";
import EnhancedTableHead from "../components/EnhancedTableHead";
import AssessmentIcon from "@mui/icons-material/Assessment";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import { useNavigate, useParams } from "react-router-dom";
import { useAppContext } from "../context/AppContext";
import moment from "moment";

const headCells = [
  {
    id: "iterationId",
    label: "Name",
    align: "left",
    sortable: true,
  },
  {
    id: "testType",
    label: "Test Type",
    align: "left",
    sortable: true,
  },
  {
    id: "browserUsed",
    label: "Browser Used",
    align: "left",
    sortable: true,
  },
  {
    id: "browserVersion",
    label: "Browser Version",
    align: "center",
    sortable: true,
  },
  {
    id: "passTestCasesCount",
    label: "Passed Count",
    align: "center",
    sortable: true,
  },
  {
    id: "failedTestCasesCount",
    label: "Fail Count",
    align: "center",
    sortable: true,
  },
  {
    id: "executionStartTime",
    label: "Start Time",
    align: "center",
    sortable: true,
  },
  {
    id: "executionEndTime",
    label: "End Time",
    align: "center",
    sortable: true,
  },
  {
    id: "action",
    label: "Action",
    align: "center",
    sortable: false,
  },
];

const Iteration = () => {
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("iterationId");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const navigate = useNavigate();
  const {
    iterations,
    totalIterations,
    getAllIterations,
    clearIteration,
    setIteration,
    project,
    getProject,
    deleteIteration,
    loggedInUser,
    iteration,
    user,
    getUser,
    clearTestCases,
    organization,
    getOrganization,
  } = useAppContext();
  const { organizationId, userId, projectId } = useParams();

  useEffect(() => {
    (async () => {
      if (!organization) await getOrganization(organizationId);
      if (userId !== loggedInUser._id && !user) await getUser(userId);
      if (!project) await getProject(userId, projectId);
      await getAllIterations(projectId);
    })();
    clearIteration();
  }, []);

  const visibleRows = useMemo(
    () =>
      stableSort(iterations || [], getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      ),
    [order, orderBy, page, rowsPerPage, iterations]
  );

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleRedirection = (event, iteration) => {
    setIteration({ iteration });
    clearTestCases();
    navigate(
      `/testcases/${organizationId}/${userId}/${projectId}/${iteration._id}`
    );
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const [modalOpen, setModalOpen] = useState(false);

  const handleDeleteIteration = (event, iteration) => {
    setIteration({ iteration });
    setModalOpen(true);
  };

  const handleClose = () => {
    setModalOpen(false);
    clearIteration();
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - totalIterations) : 0;

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <MainCard
          title={project ? `${project?.projectName}'s Iterations` : " "}
          content={false}
        ></MainCard>
      </Grid>
      <Grid item xs={12}>
        <MainCard>
          {totalIterations > 0 ? (
            <Box sx={{ width: "100%" }}>
              <Paper sx={{ width: "100%", mb: 2 }}>
                <TableContainer>
                  <Table
                    sx={{ minWidth: 750 }}
                    aria-labelledby="tableTitle"
                    size="small"
                  >
                    <EnhancedTableHead
                      sortable={true}
                      order={order}
                      orderBy={orderBy}
                      onRequestSort={handleRequestSort}
                      headCells={headCells}
                    />
                    <TableBody>
                      {visibleRows.map((iteration, index) => {
                        let executionStartTime = "-";
                        if (iteration.executionStartTime)
                          executionStartTime = moment(
                            iteration.executionStartTime
                          );
                        let executionEndTime = "-";
                        if (iteration.executionEndTime)
                          executionEndTime = moment(iteration.executionEndTime);
                        return (
                          <TableRow
                            hover
                            tabIndex={-1}
                            key={iteration._id}
                            sx={{ cursor: "pointer" }}
                          >
                            <TableCell>{iteration.iterationId}</TableCell>
                            <TableCell>{iteration.testType}</TableCell>
                            <TableCell>{iteration.browserUsed}</TableCell>
                            <TableCell align="center">
                              {iteration.browserVersion}
                            </TableCell>
                            <TableCell color="success" align="center">
                              {iteration.passTestCasesCount}
                            </TableCell>
                            <TableCell color="error" align="center">
                              {iteration.failedTestCasesCount}
                            </TableCell>
                            <TableCell align="center">
                              {executionStartTime.format(
                                "MMM Do YYYY, HH:mm:ss"
                              )}
                            </TableCell>
                            <TableCell align="center">
                              {executionEndTime.format(
                                "MMMM Do YYYY, HH:mm:ss"
                              )}
                            </TableCell>
                            <TableCell align="center">
                              {loggedInUser?.role !== "org-member" && (
                                <Tooltip
                                  title="Delete Iteration"
                                  disabled={loggedInUser?.role === "org-member"}
                                >
                                  <IconButton
                                    size="large"
                                    color="primary"
                                    tabIndex={-1}
                                    onClick={(event) =>
                                      handleDeleteIteration(event, iteration)
                                    }
                                  >
                                    <DeleteOutlinedIcon color="error" />
                                  </IconButton>
                                </Tooltip>
                              )}
                              <Tooltip title="Details">
                                <IconButton
                                  size="large"
                                  color="primary"
                                  tabIndex={-1}
                                  onClick={(event) =>
                                    handleRedirection(event, iteration)
                                  }
                                >
                                  <AssessmentIcon color="primary" />
                                </IconButton>
                              </Tooltip>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                      {emptyRows > 0 && (
                        <TableRow
                          style={{
                            height: 53 * emptyRows,
                          }}
                        >
                          <TableCell colSpan={6} />
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  rowsPerPageOptions={[10, 15, 30]}
                  component="div"
                  count={totalIterations}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </Paper>
            </Box>
          ) : (
            <Typography variant="body1" align="center">
              No Records found.
            </Typography>
          )}
          <Dialog open={modalOpen} onClose={handleClose}>
            <DialogTitle variant="h2">
              Do you want to delete this iteration?
            </DialogTitle>
            <DialogContent>
              <Typography variant="body1">
                Deleting this iteration will delete all the test cases
                associated with it.
              </Typography>
            </DialogContent>
            <DialogActions>
              <Button
                autoFocus
                onClick={async () => {
                  await deleteIteration(projectId, iteration._id);
                  handleClose();
                }}
                color="error"
              >
                Delete
              </Button>
              <Button onClick={handleClose} autoFocus>
                Cancel
              </Button>
            </DialogActions>
          </Dialog>
        </MainCard>
      </Grid>
    </Grid>
  );
};

export default Iteration;
