import { Alert, IconButton, Snackbar } from "@mui/material";
import React, { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { useAppContext } from "../context/AppContext";

const AlertSection = () => {
  const [snackPack, setSnackPack] = useState([]);
  const { showAlert, alertType, alertText, clearAlert, displayAlert } =
    useAppContext();

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    clearAlert();
  };

  useEffect(() => {
    if (snackPack.length && alertText.trim() === "") {
      setSnackPack((prev) => prev.slice(1));
      displayAlert();
    } else if (snackPack.length && alertText.trim() !== "" && showAlert) {
      clearAlert();
    }
  }, [snackPack, showAlert, alertText, displayAlert, clearAlert]);

  return (
    <Snackbar
      open={showAlert}
      autoHideDuration={3000}
      onClose={handleSnackbarClose}
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      action={
        <>
          <IconButton
            aria-label="close"
            color="inherit"
            sx={{ p: 0.5 }}
            onClick={handleSnackbarClose}
          >
            <CloseIcon />
          </IconButton>
        </>
      }
    >
      <Alert
        onClose={handleSnackbarClose}
        severity={alertType || "success"}
        sx={{ width: "100%" }}
      >
        {alertText}
      </Alert>
    </Snackbar>
  );
};

export default AlertSection;
