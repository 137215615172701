import React, { useMemo, useState } from "react";
import MainCard from "../../components/MainCard";
import {
  Badge,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Fab,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Toolbar,
  Tooltip,
  Typography,
  Zoom,
  useTheme,
} from "@mui/material";
import EnhancedTableHead from "../../components/EnhancedTableHead";
import moment from "moment";
import { useNavigate, useParams } from "react-router-dom";
import { getComparator, stableSort } from "../../utils";
import { useAppContext } from "../../context/AppContext";

import AccountBoxIcon from "@mui/icons-material/AccountBox";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import AnalyticsIcon from "@mui/icons-material/Analytics";
import MembersModel from "./MembersModel";
import AddIcon from "@mui/icons-material/Add";
import EditProjectModal from "./EditProjectModal";

const ProjectTable = ({ projects, totalProjects }) => {
  let headCells = [
    {
      id: "projectName",
      label: "Project Name",
      sortable: true,
      align: "left",
    },
    {
      id: "id",
      label: "API Key",
      align: "left",
      sortable: false,
    },
    {
      id: "projectMembers",
      label: "Members",
      align: "center",
      sortable: false,
    },
    {
      id: "createdAt",
      label: "Created At",
      align: "center",
      sortable: true,
    },
    {
      id: "updatedAt",
      label: "Updated At",
      align: "center",
      sortable: true,
    },
    {
      id: "action",
      label: "Action",
      align: "center",
      sortable: false,
    },
  ];

  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState(headCells[0].id);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const navigate = useNavigate();
  const [membersModalOpen, setMembersModalOpen] = useState(false);
  const [projectModalOpen, setProjectModalOpen] = useState(false);
  const theme = useTheme();

  const {
    setProject,
    deleteProject,
    project,
    clearProject,
    loggedInUser,
    getAllProjects,
    clearOrgMembers,
    updateProject,
    createProject,
    clearIterations,
    user,
  } = useAppContext();

  const { organizationId, userId } = useParams();

  if (organizationId === "all") {
    headCells.splice(1, 1, {
      id: "organization",
      label: "Organization",
      align: "left",
      sortable: true,
    });
    headCells.splice(5, 1);
  }

  const visibleRows = useMemo(
    () =>
      stableSort(projects, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      ),
    [order, orderBy, page, rowsPerPage, projects]
  );

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleRedirection = (event, project) => {
    setProject({ project });
    clearIterations();
    navigate(`/iterations/${organizationId}/${userId}/${project._id}`);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleDeleteProject = async (event, project) => {
    setProject({ project });
    setModalOpen(true);
  };

  const handleMembersModelOpen = (event, project) => {
    setProject({ project });
    setMembersModalOpen(true);
  };

  const handleMembersModelClose = async () => {
    setMembersModalOpen(false);
    clearProject();
  };

  const handleDeleteProjectMember = async (projectMember) => {
    setMembersModalOpen(false);
    await updateProject({
      projectId: project._id,
      organization: organizationId,
      projectName: project.projectName,
      projectMembers: project.projectMembers.filter(
        (member) => member._id !== projectMember._id
      ),
    });

    await getAllProjects({ userId });
  };

  const handleProjectModalOpen = async (event, project) => {
    // await getOrgMembers(
    //   loggedInUser?.role === "org-member"
    //     ? loggedInUser?.organizationId
    //     : user?.organizationId
    // );
    if (project) setProject({ project });
    else clearProject();
    setProjectModalOpen(true);
  };

  const handleProjectModelClose = async () => {
    setProjectModalOpen(false);
    clearProject();
    clearOrgMembers();
  };

  const handleEditProject = async ({ projectName, projectMembers }) => {
    if (projectName.trim() !== "" && projectMembers.length > 0) {
      if (project)
        await updateProject({
          projectId: project?._id,
          organization: organizationId,
          projectName,
          projectMembers,
        });
      else {
        await createProject(projectName, projectMembers, organizationId);
        setProjectModalOpen(false);
      }
    }
    await getAllProjects({ userId });
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - totalProjects) : 0;

  const transitionDuration = {
    enter: theme.transitions.duration.enteringScreen,
    exit: theme.transitions.duration.leavingScreen,
  };

  const [modalOpen, setModalOpen] = useState(false);

  const handleClose = () => {
    clearProject();
    setModalOpen(false);
  };

  return (
    <MainCard>
      <Box sx={{ width: "100%" }}>
        <Paper sx={{ width: "100%", mb: 2 }}>
          {totalProjects > 0 ? (
            <TableContainer>
              <Table
                sx={{ minWidth: 750 }}
                aria-labelledby="tableTitle"
                size="small"
              >
                <EnhancedTableHead
                  sortable={true}
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort}
                  headCells={headCells}
                />
                <TableBody>
                  {visibleRows.map((visibleProject) => {
                    let createdDate = moment(visibleProject.createdAt);
                    let updatedDate = moment(visibleProject.updatedAt);
                    return (
                      <TableRow
                        hover
                        tabIndex={-1}
                        key={visibleProject._id}
                        sx={{ cursor: "pointer" }}
                      >
                        <TableCell>{visibleProject.projectName}</TableCell>
                        <TableCell>
                          {organizationId !== "all" ? (
                            <code>{visibleProject._id}</code>
                          ) : (
                            visibleProject.organizationName
                          )}
                        </TableCell>

                        <TableCell align="center">
                          <Tooltip title="Project Members">
                            <IconButton
                              size="large"
                              color="primary"
                              tabIndex={-1}
                              onClick={(event) =>
                                handleMembersModelOpen(event, visibleProject)
                              }
                            >
                              <Badge
                                color="secondary"
                                badgeContent={
                                  visibleProject.projectMembers.length
                                }
                              >
                                <AccountBoxIcon color="primary" />
                              </Badge>
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                        <TableCell align="center">
                          {createdDate.format("MMMM Do YYYY, HH:mm:ss")}
                        </TableCell>
                        <TableCell align="center">
                          {updatedDate.format("MMMM Do YYYY, HH:mm:ss")}
                        </TableCell>
                        {organizationId !== "all" && (
                          <TableCell align="center">
                            {loggedInUser &&
                              loggedInUser.role !== "org-member" && (
                                <>
                                  <Tooltip
                                    title="Edit Project"
                                    disabled={
                                      loggedInUser &&
                                      loggedInUser.role === "org-member"
                                    }
                                  >
                                    <IconButton
                                      size="large"
                                      color="primary"
                                      tabIndex={-1}
                                      onClick={(event) =>
                                        handleProjectModalOpen(
                                          event,
                                          visibleProject
                                        )
                                      }
                                    >
                                      <EditOutlinedIcon color="secondary" />
                                    </IconButton>
                                  </Tooltip>
                                  <Tooltip
                                    title="Delete Project"
                                    disabled={
                                      loggedInUser &&
                                      loggedInUser.role === "org-member"
                                    }
                                  >
                                    <IconButton
                                      size="large"
                                      color="primary"
                                      tabIndex={-1}
                                      onClick={(event) =>
                                        handleDeleteProject(
                                          event,
                                          visibleProject
                                        )
                                      }
                                    >
                                      <DeleteOutlinedIcon color="error" />
                                    </IconButton>
                                  </Tooltip>
                                </>
                              )}

                            <Tooltip title="Details">
                              <IconButton
                                size="large"
                                color="primary"
                                tabIndex={-1}
                                onClick={(event) =>
                                  handleRedirection(event, visibleProject)
                                }
                              >
                                <AnalyticsIcon color="primary" />
                              </IconButton>
                            </Tooltip>
                          </TableCell>
                        )}
                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow
                      style={{
                        height: 53 * emptyRows,
                      }}
                    >
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <Grid spacing={2} container justifyContent="center">
              <Grid item>
                <Typography variant="body1" align="center">
                  No Records found.
                </Typography>
              </Grid>
            </Grid>
          )}
          <Grid
            spacing={2}
            container
            justifyContent={totalProjects > 0 ? "center" : "left"}
          >
            <Grid
              item
              md={3}
              order={totalProjects > 0 ? { xs: 2, md: 1 } : { xs: 1, md: 1 }}
            >
              {organizationId !== "all" &&
                loggedInUser?.role !== "org-member" &&
                user?.role !== "org-member" && (
                  <Toolbar sx={{ pl: "0 !important", pb: 0, pt: "15px" }}>
                    <Zoom
                      in={true}
                      timeout={transitionDuration}
                      style={{
                        transitionDelay: `${transitionDuration.enter}ms`,
                      }}
                      unmountOnExit
                    >
                      <Fab
                        variant="extended"
                        size="medium"
                        color="primary"
                        onClick={(event) => {
                          handleProjectModalOpen();
                        }}
                      >
                        <AddIcon sx={{ mr: 1 }} />
                        Add Project
                      </Fab>
                    </Zoom>
                  </Toolbar>
                )}
            </Grid>
            {totalProjects > 0 && (
              <Grid item md={9} order={{ xs: 1, lg: 2 }}>
                <TablePagination
                  rowsPerPageOptions={[10, 15, 30]}
                  component="div"
                  count={projects.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </Grid>
            )}
          </Grid>
        </Paper>
      </Box>

      <MembersModel
        open={membersModalOpen}
        handleClose={handleMembersModelClose}
        project={project}
        handleDeleteProjectMember={handleDeleteProjectMember}
      />
      <EditProjectModal
        modalOpen={projectModalOpen}
        handleClose={handleProjectModelClose}
        project={project}
        handleUpdateProject={handleEditProject}
      />
      <Dialog open={modalOpen} onClose={handleClose}>
        <DialogTitle variant="h2">
          Do you want to delete this project?
        </DialogTitle>
        <DialogContent>
          <Typography variant="body1">
            Deleting this project will delete all the iterations and test cases
            associated with it.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={async () => {
              await deleteProject(project?._id);
              await getAllProjects({ userId });
              handleClose();
            }}
            color="error"
          >
            Delete
          </Button>
          <Button onClick={handleClose} autoFocus>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </MainCard>
  );
};

export default ProjectTable;
