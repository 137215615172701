import { Grid, Typography } from "@mui/material";
import React, { useEffect } from "react";
import TestCasesTable from "./TestCasesTable";
import { ResultCard, CardType } from "./ResultCard";
import ResultChart from "./ResultChart";
import SummaryTable from "./SummaryTable";
import { useAppContext } from "../../context/AppContext";
import { useParams } from "react-router-dom";
import MainCard from "../../components/MainCard";

const TestCases = (props) => {
  const {
    testCases,
    totalTestCases,
    getAllTestCases,
    iteration,
    getIteration,
    isLoading,
    user,
    getUser,
    project,
    getProject,
    organization,
    getOrganization,
    loggedInUser,
  } = useAppContext();
  const { organizationId, userId, projectId, iterationId } = useParams();

  useEffect(() => {
    (async () => {
      if (!organization) await getOrganization(organizationId);
      if (userId !== loggedInUser._id && !user) await getUser(userId);
      if (!project) await getProject(userId, projectId);
      if (!iteration) await getIteration(projectId, iterationId);
      await getAllTestCases(iterationId);
    })();
  }, []);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <MainCard title="Dashboard" content={false}></MainCard>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item md={6} xs={12}>
            {iteration && (
              <SummaryTable isLoading={false} iteration={iteration} />
            )}
          </Grid>
          <Grid item md={6} xs={12}>
            {totalTestCases > 0 ? (
              <ResultChart
                isLoading={isLoading}
                passCount={iteration ? iteration.passTestCasesCount : 0}
                failCount={iteration ? iteration.failedTestCasesCount : 0}
                skipCount={iteration ? iteration.skipTestCasesCount : 0}
              />
            ) : (
              <MainCard
                sx={{
                  minHeight: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Typography variant="body1" align="center">
                  No data available.
                </Typography>
              </MainCard>
            )}
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={3}>
            <ResultCard
              isLoading={isLoading}
              count={totalTestCases}
              cardType={CardType.total}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <ResultCard
              isLoading={isLoading}
              count={iteration ? iteration.passTestCasesCount : 0}
              cardType={CardType.success}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <ResultCard
              isLoading={isLoading}
              count={iteration ? iteration.failedTestCasesCount : 0}
              cardType={CardType.fail}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <ResultCard
              isLoading={isLoading}
              count={iteration ? iteration.skipTestCasesCount : 0}
              cardType={CardType.skip}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <TestCasesTable testCases={testCases || []} />
      </Grid>
    </Grid>
  );
};

export default TestCases;
