import { useEffect } from "react";
import { useTheme } from "@mui/material/styles";

import ApexCharts from "apexcharts";
import Chart from "react-apexcharts";

import MainCard from "../../components/MainCard";

const chartData = (theme) => {
  return {
    type: "donut",
    height: 281,
    options: {
      chart: {
        id: "donut-chart",
        foreColor: "#000000",
        stacked: true,
        toolbar: {
          show: true,
        },
        zoom: {
          enabled: true,
        },
      },
      // responsive: [
      //   {
      //     breakpoint: 480,
      //     options: {
      //       legend: {
      //         position: "bottom",
      //       },
      //     },
      //   },
      // ],
      plotOptions: {
        pie: {
          expandOnClick: false,
          donut: {
            size: "35%",
          },
        },
      },
      legend: {
        show: true,
        fontSize: "14px",
        fontFamily: `'Roboto', sans-serif`,
        position: "bottom",
        offsetX: 20,
        labels: {
          useSeriesColors: false,
        },
        markers: {
          width: 16,
          height: 16,
          radius: 5,
        },
        itemMargin: {
          horizontal: 15,
          vertical: 8,
        },
      },
      fill: {
        type: "solid",
      },
      dataLabels: {
        enabled: true,
      },
      grid: {
        show: true,
      },
      labels: ["Passed Test Cases", "Failed Test Cases", "Skipped Test Cases"],
      colors: [
        theme.palette.success.main,
        theme.palette.error.main,
        theme.palette.orange.main,
      ],
    },
  };
};

const ResultChart = ({
  isLoading,
  passCount = 0,
  failCount = 0,
  skipCount = 0,
}) => {
  const theme = useTheme();

  const grey200 = theme.palette.grey[200];
  const grey500 = theme.palette.grey[500];

  useEffect(() => {
    const newChartData = {
      ...chartData(theme).options,
      colors: [
        theme.palette.success.main,
        theme.palette.error.main,
        theme.palette.orange.main,
      ],
      grid: {
        borderColor: grey200,
      },
      tooltip: {
        theme: "light",
      },
      legend: {
        labels: {
          colors: grey500,
        },
      },
    };

    // do not load chart when loading
    if (!isLoading) {
      ApexCharts.exec(`donut-chart`, "updateOptions", newChartData);
    }
  }, [grey200, isLoading, grey500, theme]);

  return (
    <>
      {/* TODO: Inverse isLoading condition */}
      {isLoading ? (
        // TODO: Add skeletons to handle loading
        // <SkeletonTotalGrowthBarChart />
        <></>
      ) : (
        <MainCard
          sx={{
            minHeight: "100%",
          }}
        >
          <Chart
            {...chartData(theme)}
            series={[passCount, failCount, skipCount]}
          />
        </MainCard>
      )}
    </>
  );
};

export default ResultChart;
