import { useRoutes } from "react-router-dom";

import UserRoutes from "./UserRoutes";
import AuthRoutes from "./AuthRoutes";
import { Error } from "../views";

export default function ThemeRoutes() {
  return useRoutes([
    UserRoutes(),
    AuthRoutes,
    {
      path: "*",
      element: <Error />,
    },
  ]);
}
