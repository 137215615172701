import { Box, CssBaseline, Grid, styled, useTheme } from "@mui/material";
import React from "react";
import { Outlet } from "react-router-dom";
import MainCard from "../components/MainCard";

// styles
const Main = styled("main")(({ theme, open }) => ({
  backgroundColor: theme.palette.primary.light,
  minHeight: "100vh",
  minWidth: "100vw",
}));

const SecondaryLayout = () => {
  const theme = useTheme();

  return (
    <>
      <CssBaseline />
      <Box sx={{ display: "flex" }}>
        <Main theme={theme}>
          <Grid
            container
            direction="column"
            justifyContent="flex-start"
            sx={{ minHeight: "100vh" }}
          >
            <Grid item xs={12}>
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                sx={{ minHeight: "calc(100vh - 68px)" }}
              >
                <Grid item sx={{ m: { xs: 1, sm: 3 }, mb: 0 }}>
                  <MainCard
                    sx={{
                      maxWidth: { xs: 400, lg: 475 },
                      margin: { xs: 2.5, md: 3 },
                      "& > *": {
                        flexGrow: 1,
                        flexBasis: "50%",
                      },
                    }}
                    content={false}
                  >
                    <Box sx={{ p: { xs: 2, sm: 3, xl: 5 } }}>
                      <Outlet />
                    </Box>
                  </MainCard>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Main>
      </Box>
    </>
  );
};

export default SecondaryLayout;
