import React, { useContext, useReducer, createContext } from "react";
import reducer from "./Reducer";
import {
  CLEAR_ALERT,
  CLEAR_ITERATION,
  CLEAR_ITERATIONS,
  CLEAR_LOADING,
  CLEAR_ORGANIZATION,
  CLEAR_ORGANIZATION_MEMBERS,
  CLEAR_ORGANIZATIONS,
  CLEAR_PROJECT,
  CLEAR_PROJECTS,
  CLEAR_TEST_CASES,
  CLEAR_USER,
  CLEAR_USERS,
  DISPLAY_ALERT,
  GET_ITERATIONS_SUCCESS,
  GET_ORGANIZATION_MEMBERS_SUCCESS,
  GET_ORGANIZATIONS_SUCCESS,
  GET_PROJECTS_SUCCESS,
  GET_TEST_CASES_SUCCESS,
  GET_USERS_SUCCESS,
  LOGIN_SUCCESS,
  LOGOUT_SUCCESS,
  PASSWORD_CHANGE_SUCCESS,
  SET_ITERATION,
  SET_LOADING,
  SET_ORGANIZATION,
  SET_PROJECT,
  SET_USER,
  UPDATE_USER_SUCCESS,
} from "./Action";
import axios from "axios";

const token = localStorage.getItem("token") || sessionStorage.getItem("token");
const loggedInUser =
  localStorage.getItem("loggedInUser") ||
  sessionStorage.getItem("loggedInUser");

const initialState = {
  isLoading: false,
  showAlert: false,
  alertType: "",
  alertText: "",
  theme: "light",
  loggedInUser: loggedInUser ? JSON.parse(loggedInUser) : null,
  token: token,
  organizations: null,
  totalOrganizations: 0,
  users: null,
  totalUsers: 0,
  projects: null,
  totalProjects: 0,
  iterations: null,
  totalIterations: 0,
  testCases: null,
  totalTestCases: 0,
  organization: null,
  user: null,
  project: null,
  iteration: null,
  orgMembers: null,
};

const AppContext = createContext();

const AppProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  // #region Axios
  const Axios = axios.create({
    baseURL: "https://app.11automation.com/api/v1",
    // baseURL: "http://localhost:4000/api/v1",
  });
  const AuthAxios = axios.create({
    baseURL: "https://app.11automation.com/api/v1",
    // baseURL: "http://localhost:4000/api/v1",
  });

  AuthAxios.interceptors.request.use(
    (config) => {
      config.headers["Authorization"] = `Bearer ${state.token}`;
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  AuthAxios.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response.status === 401) {
        console.log("AUTH ERROR");
        logout();
      }
      return Promise.reject(error);
    }
  );
  // #endregion

  // #region Local/session storage
  const updateUserToLocalStorage = ({ user }) => {
    if (localStorage.getItem("loggedInUser")) {
      localStorage.setItem("loggedInUser", JSON.stringify(user));
    } else {
      sessionStorage.setItem("loggedInUser", JSON.stringify(user));
    }
  };

  const addUserToLocalStorage = ({ user, token, remember }) => {
    if (remember) {
      localStorage.setItem("loggedInUser", JSON.stringify(user));
      localStorage.setItem("token", token);
    } else {
      sessionStorage.setItem("loggedInUser", JSON.stringify(user));
      sessionStorage.setItem("token", token);
    }
  };

  const removeUserFromLocalStorage = () => {
    localStorage.removeItem("loggedInUser");
    localStorage.removeItem("token");
    sessionStorage.removeItem("loggedInUser");
    sessionStorage.removeItem("token");
  };
  // #endregion

  // #region AUTH
  const login = async ({ loginUser, remember }) => {
    dispatch({ type: SET_LOADING });
    try {
      const response = await Axios.post("/auth/login", loginUser);

      const { user, token } = response.data;
      addUserToLocalStorage({ user, token, remember });
      displayAlert("success", "Logged in successfully.");
      dispatch({
        type: LOGIN_SUCCESS,
        payload: { user, token },
      });
      return user.newPassword ? "/change-password" : "/";
    } catch (error) {
      if (error.response) displayAlert("error", error.response.data.message);
      else displayAlert("error", "Login failed.");
      console.error(error);
      return "";
    } finally {
      dispatch({ type: CLEAR_LOADING });
    }
  };

  const changePassword = async ({ updatedPassword }) => {
    dispatch({ type: SET_LOADING });
    try {
      const response = await AuthAxios.post("/auth/change-password", {
        updatedPassword,
      });
      const { user } = response.data;
      updateUserToLocalStorage({ user });
      dispatch({
        type: PASSWORD_CHANGE_SUCCESS,
        payload: { user },
      });
      displayAlert("success", "Password changed successfully.");
      return "/";
    } catch (error) {
      if (error.response) displayAlert("error", error.response.data.message);
      else displayAlert("error", "Password change failed.");
      console.error(error);
      return "";
    } finally {
      dispatch({ type: CLEAR_LOADING });
    }
  };

  const logout = () => {
    removeUserFromLocalStorage();
    displayAlert("success", "Logged out successfully.");
    dispatch({
      type: LOGOUT_SUCCESS,
    });
    return "/login";
  };

  const updateUser = async ({ UpdatedUser }) => {
    dispatch({ type: SET_LOADING });
    try {
      const response = await AuthAxios.post("/auth/updateUser", UpdatedUser);

      const { user } = response.data;
      displayAlert("success", "User updated successfully.");
      dispatch({
        type: UPDATE_USER_SUCCESS,
        payload: { user },
      });
      updateUserToLocalStorage({ user });
    } catch (error) {
      if (error.response) displayAlert("error", error.response.data.message);
      else displayAlert("error", "User update failed.");
      console.error(error);
    } finally {
      dispatch({ type: CLEAR_LOADING });
    }
  };

  const resetPassword = async ({ email }) => {
    dispatch({ type: SET_LOADING });
    try {
      const response = await Axios.post("/auth/reset-password", { email });

      if (response.status === 200) {
        displayAlert("success", "Password reset successfully. Please login.");
      }
      dispatch({
        type: CLEAR_LOADING,
      });
      return "/login";
    } catch (error) {
      if (error.response) displayAlert("error", error.response.data.message);
      else displayAlert("error", "Password reset failed.");
      console.error(error);
      return "";
    } finally {
      dispatch({ type: CLEAR_LOADING });
    }
  };

  const displayAlert = (alertType, alertText) => {
    dispatch({
      type: DISPLAY_ALERT,
      payload: {
        alertType,
        alertText,
      },
    });
  };
  const clearAlert = () => {
    dispatch({ type: CLEAR_ALERT });
  };
  // #endregion

  // #region Projects
  const getAllProjects = async ({ organizationId, userId }) => {
    dispatch({ type: SET_LOADING });
    try {
      const response = await AuthAxios.get(`/projects`, {
        params: {
          orgId: organizationId || null,
          userId,
        },
      });

      const { projects, totalProjects } = response.data;
      dispatch({
        type: GET_PROJECTS_SUCCESS,
        payload: { projects, totalProjects },
      });
    } catch (error) {
      console.log(error);
      if (error.response) displayAlert("error", error.response.data.message);
      else displayAlert("error", "Failed to get projects.");
    } finally {
      dispatch({ type: CLEAR_LOADING });
    }
  };

  const getProject = async (userId, projectId) => {
    dispatch({ type: SET_LOADING });
    try {
      const response = await AuthAxios.get(`/projects/${projectId}`, {
        params: {
          userId,
        },
      });

      const { project } = response.data;
      dispatch({
        type: SET_PROJECT,
        payload: { project },
      });
    } catch (error) {
      console.log(error);
      if (error.response) displayAlert("error", error.response.data.message);
      else displayAlert("error", "Failed to get project.");
    } finally {
      dispatch({ type: CLEAR_LOADING });
    }
  };

  const deleteProject = async (projectId) => {
    dispatch({ type: SET_LOADING });
    try {
      await AuthAxios.delete(`/projects/${projectId}`);
      displayAlert("success", "Project deleted successfully.");
    } catch (error) {
      console.log(error);
      if (error.response) displayAlert("error", error.response.data.message);
      else displayAlert("error", "Failed to delete project.");
    } finally {
      dispatch({ type: CLEAR_LOADING });
    }
  };

  const setProject = async ({ project }) => {
    dispatch({ type: SET_PROJECT, payload: { project } });
  };

  const clearProject = async () => {
    dispatch({ type: CLEAR_PROJECT });
  };

  const clearProjects = async () => {
    dispatch({ type: CLEAR_PROJECTS });
  };

  const updateProject = async ({
    projectId,
    projectName,
    organization,
    projectMembers,
  }) => {
    dispatch({ type: SET_LOADING });
    try {
      const response = await AuthAxios.put(`/projects/${projectId}`, {
        projectName,
        organization,
        projectMembers: projectMembers.map(
          (projectMember) => projectMember._id
        ),
      });
      const { project: UpdatedProject } = response.data;
      setProject({ project: UpdatedProject });
      displayAlert("success", "Project updated successfully.");
    } catch (error) {
      // TODO Handle Projects update failure
      console.log(error);
      if (error.response) displayAlert("error", error.response.data.message);
      else displayAlert("error", "Failed to update project.");
    } finally {
      dispatch({ type: CLEAR_LOADING });
    }
  };

  const createProject = async (projectName, projectMembers, organization) => {
    dispatch({ type: SET_LOADING });
    try {
      await AuthAxios.post(`/projects/`, {
        projectName: projectName,
        organization,
        projectMembers: projectMembers.map(
          (projectMember) => projectMember._id
        ),
      });
      displayAlert("success", "Project created successfully.");
    } catch (error) {
      console.log(error);
      if (error.response) displayAlert("error", error.response.data.message);
      else displayAlert("error", "Failed to create project.");
    } finally {
      dispatch({ type: CLEAR_LOADING });
    }
  };

  // #endregion

  // #region Iterations
  const getAllIterations = async (projectId) => {
    dispatch({ type: SET_LOADING });
    try {
      const response = await AuthAxios.get(`/iterations/${projectId}`);
      const { iterations, totalIterations } = response.data;
      dispatch({
        type: GET_ITERATIONS_SUCCESS,
        payload: { iterations, totalIterations },
      });
    } catch (error) {
      console.log(error);
      if (error.response) displayAlert("error", error.response.data.message);
      else displayAlert("error", "Failed to get iterations.");
    } finally {
      dispatch({ type: CLEAR_LOADING });
    }
  };

  const getIteration = async (projectId, iterationId) => {
    dispatch({ type: SET_LOADING });
    try {
      const response = await AuthAxios.get(
        `/iterations/${projectId}/${iterationId}`
      );

      const { iteration } = response.data;
      setIteration({ iteration });
    } catch (error) {
      console.log(error);
      if (error.response) displayAlert("error", error.response.data.message);
      else displayAlert("error", "Failed to get iteration.");
    } finally {
      dispatch({ type: CLEAR_LOADING });
    }
  };

  const deleteIteration = async (projectId, iterationId) => {
    dispatch({ type: SET_LOADING });
    try {
      await AuthAxios.delete(`/iterations/${projectId}/${iterationId}`);
      // TODO: Handle response and launch an alert
      getAllIterations(projectId);
      displayAlert("success", "Iteration deleted successfully.");
    } catch (error) {
      console.log(error);
      if (error.response) displayAlert("error", error.response.data.message);
      else displayAlert("error", "Failed to delete iteration.");
    } finally {
      dispatch({ type: CLEAR_LOADING });
    }
  };

  const setIteration = async ({ iteration }) => {
    dispatch({ type: SET_ITERATION, payload: { iteration } });
  };

  const clearIteration = async () => {
    dispatch({ type: CLEAR_ITERATION });
  };

  const clearIterations = async () => {
    dispatch({ type: CLEAR_ITERATIONS });
  };

  // #endregion

  // #region Test Cases
  const getAllTestCases = async (iterationId) => {
    dispatch({ type: SET_LOADING });
    try {
      const response = await AuthAxios.get(`/test-cases/${iterationId}`);

      const { testCases, totalTestCases } = response.data;

      dispatch({
        type: GET_TEST_CASES_SUCCESS,
        payload: { testCases, totalTestCases },
      });
    } catch (error) {
      // TODO Handle Test Cases  Get failure
      console.log(error);
      if (error.response) displayAlert("error", error.response.data.message);
      else displayAlert("error", "Failed to get test cases.");
    } finally {
      dispatch({ type: CLEAR_LOADING });
    }
  };

  const deleteTestCase = async (iterationId, testCaseId) => {
    dispatch({ type: SET_LOADING });
    try {
      await AuthAxios.delete(`/test-cases/${iterationId}/${testCaseId}`);

      getAllTestCases(iterationId);
      displayAlert("success", "Test case deleted successfully.");
    } catch (error) {
      // TODO Handle Test Cases  Get failure
      console.log(error);
      if (error.response) displayAlert("error", error.response.data.message);
      else displayAlert("error", "Failed to delete test case.");
    } finally {
      dispatch({ type: CLEAR_LOADING });
    }
  };

  const clearTestCases = async () => {
    dispatch({ type: CLEAR_TEST_CASES });
  };

  // #endregion

  // #region Users
  const getAllUsers = async (organization) => {
    dispatch({ type: SET_LOADING });
    try {
      let url =
        organization !== "all" ? `/users?orgId=${organization}` : "/users";
      const response = await AuthAxios.get(url);

      const { users, totalUsers } = response.data;
      dispatch({
        type: GET_USERS_SUCCESS,
        payload: { users, totalUsers },
      });
    } catch (error) {
      // TODO Handle Projects Get failure
      console.log(error);
      if (error.response) displayAlert("error", error.response.data.message);
      else displayAlert("error", "Failed to get users.");
    } finally {
      dispatch({ type: CLEAR_LOADING });
    }
  };

  const getUser = async (userId) => {
    dispatch({ type: SET_LOADING });
    try {
      let url = `/users/${userId}`;
      const response = await AuthAxios.get(url);

      const { user } = response.data;
      setUser(user);
    } catch (error) {
      console.log(error);
      if (error.response) displayAlert("error", error.response.data.message);
      else displayAlert("error", "Failed to get users.");
    } finally {
      dispatch({ type: CLEAR_LOADING });
    }
  };

  const deleteUser = async (userId) => {
    dispatch({ type: SET_LOADING });
    try {
      await AuthAxios.delete(`/users/${userId}`);
      displayAlert("success", "User deleted successfully.");
    } catch (error) {
      // TODO Handle Projects Get failure
      console.log(error);
      if (error.response) displayAlert("error", error.response.data.message);
      else displayAlert("error", "Failed to delete user.");
    } finally {
      dispatch({ type: CLEAR_LOADING });
    }
  };

  const setUser = async (user) => {
    dispatch({ type: SET_USER, payload: { user } });
  };

  const clearUser = async () => {
    dispatch({ type: CLEAR_USER });
  };

  const updateUserAdmin = async (updatedUser) => {
    dispatch({ type: SET_LOADING });
    try {
      const response = await AuthAxios.put(`/users/${updatedUser._id}`, {
        ...updatedUser,
      });
      const { user } = response.data;
      displayAlert("success", "User updated successfully.");
      setUser(user);
    } catch (error) {
      console.log(error);
      if (error.response) displayAlert("error", error.response.data.message);
      else displayAlert("error", "Failed to update user.");
    } finally {
      dispatch({ type: CLEAR_LOADING });
    }
  };

  const createUser = async (user, organization) => {
    dispatch({ type: SET_LOADING });
    try {
      await AuthAxios.post(`/users`, {
        ...user,
        organization,
      });
      displayAlert("success", "User created successfully.");
    } catch (error) {
      console.log(error);
      if (error.response) displayAlert("error", error.response.data.message);
      else displayAlert("error", "Failed to create user.");
    } finally {
      dispatch({ type: CLEAR_LOADING });
    }
  };

  const clearUsers = async () => {
    dispatch({ type: CLEAR_USERS });
  };
  // #endregion

  // #region Organization

  const getAllOrganizations = async () => {
    dispatch({ type: SET_LOADING });
    try {
      const response = await AuthAxios.get("/organizations");
      let { organizations, totalOrganizations } = response.data;
      dispatch({
        type: GET_ORGANIZATIONS_SUCCESS,
        payload: { organizations, totalOrganizations },
      });
    } catch (error) {
      console.log(error);
      if (error.response) displayAlert("error", error.response.data.message);
      else displayAlert("error", "Failed to get organizations.");
    } finally {
      dispatch({ type: CLEAR_LOADING });
    }
  };

  const clearOrganizations = async () => {
    dispatch({ type: CLEAR_ORGANIZATIONS });
  };

  const setOrganization = async (organization) => {
    dispatch({ type: SET_ORGANIZATION, payload: { organization } });
  };

  const clearOrganization = async () => {
    dispatch({ type: CLEAR_ORGANIZATION });
  };

  const getOrganization = async (organizationId) => {
    dispatch({ type: SET_LOADING });
    try {
      const response = await AuthAxios.get(`/organizations/${organizationId}`);
      const { organization } = response.data;
      setOrganization(organization);
    } catch (error) {
      console.log(error);
      if (error.response) displayAlert("error", error.response.data.message);
      else displayAlert("error", "Failed to get organization.");
    } finally {
      dispatch({ type: CLEAR_LOADING });
    }
  };

  const updateOrganization = async (organization) => {
    dispatch({ type: SET_LOADING });
    try {
      const response = await AuthAxios.put(
        `/organizations/${organization.organizationId}`,
        {
          organizationName: organization.organizationName,
          organizationDescription: organization.organizationDescription,
          organizationMembers: organization.organizationMembers.map(
            (member) => member._id
          ),
        }
      );
      const { organization: UpdatedOrganization } = response.data;
      setOrganization(UpdatedOrganization);
      displayAlert("success", "Organization updated successfully.");
    } catch (error) {
      // TODO Handle Projects update failure
      console.log(error);
      if (error.response) displayAlert("error", error.response.data.message);
      else displayAlert("error", "Failed to update organization.");
    } finally {
      dispatch({ type: CLEAR_LOADING });
    }
  };

  const createOrganization = async (
    organizationName,
    organizationDescription,
    organizationMembers
  ) => {
    dispatch({ type: SET_LOADING });
    try {
      await AuthAxios.post(`/organizations`, {
        organizationName,
        organizationDescription,
        organizationMembers: organizationMembers.map((member) => member._id),
      });
      displayAlert("success", "Organization created successfully.");
    } catch (error) {
      console.log(error);
      if (error.response) displayAlert("error", error.response.data.message);
      else displayAlert("error", "Failed to create organization.");
    } finally {
      dispatch({ type: CLEAR_LOADING });
    }
  };

  const deleteOrganization = async (organizationId) => {
    dispatch({ type: SET_LOADING });
    try {
      await AuthAxios.delete(`/organizations/${organizationId}`);
      displayAlert("success", "Organization deleted successfully.");
    } catch (error) {
      console.log(error);
      if (error.response) displayAlert("error", error.response.data.message);
      else displayAlert("error", "Failed to delete organization.");
    } finally {
      dispatch({ type: CLEAR_LOADING });
    }
  };

  const getOrgMembers = async (organization) => {
    dispatch({ type: SET_LOADING });

    try {
      const response = await AuthAxios.post(`/organization/members`, {
        organization,
      });
      const { organizationMembers } = response.data;

      dispatch({
        type: GET_ORGANIZATION_MEMBERS_SUCCESS,
        payload: {
          orgMembers: organizationMembers,
        },
      });
    } catch (error) {
      // TODO Handle Projects update failure
      console.log(error);
      dispatch({ type: CLEAR_ORGANIZATION_MEMBERS });
      if (error.response) displayAlert("error", error.response.data.message);
      else displayAlert("error", "Failed to get organization members.");
    } finally {
      dispatch({ type: CLEAR_LOADING });
    }
  };

  const clearOrgMembers = async () => {
    dispatch({ type: CLEAR_ORGANIZATION_MEMBERS });
  };

  // #endregion

  return (
    <AppContext.Provider
      value={{
        ...state,
        login,
        logout,
        updateUser,
        getAllProjects,
        getProject,
        getAllIterations,
        getIteration,
        getAllTestCases,
        setProject,
        clearProject,
        setIteration,
        clearIteration,
        deleteProject,
        createProject,
        deleteIteration,
        deleteTestCase,
        getOrgMembers,
        clearOrgMembers,
        updateProject,
        deleteUser,
        createUser,
        getUser,
        getAllUsers,
        updateUserAdmin,
        setUser,
        clearUser,
        resetPassword,
        displayAlert,
        clearAlert,
        changePassword,
        clearProjects,
        clearIterations,
        clearTestCases,
        clearUsers,
        getAllOrganizations,
        clearOrganizations,
        setOrganization,
        clearOrganization,
        getOrganization,
        updateOrganization,
        createOrganization,
        deleteOrganization,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

const useAppContext = () => {
  return useContext(AppContext);
};

export { AppProvider, initialState, useAppContext };
