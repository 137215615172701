import { lazy } from "react";

import Loadable from "../components/Loadable";
import SecondaryLayout from "../secondaryLayout";

const ResetPasswordLoader = Loadable(
  lazy(() => import("../views/ResetPassword"))
);
const LoginLoader = Loadable(lazy(() => import("../views/Login")));
const ChangePasswordLoader = Loadable(
  lazy(() => import("../views/ChangePassword"))
);
const AuthRoutes = {
  path: "/",
  element: <SecondaryLayout />,
  caseSensitive: false,
  children: [
    { path: "login", element: <LoginLoader /> },
    { path: "reset-password", element: <ResetPasswordLoader /> },
    { path: "change-password", element: <ChangePasswordLoader /> },
  ],
};

export default AuthRoutes;
