import {
  CLEAR_ALERT,
  CLEAR_ITERATION,
  CLEAR_LOADING,
  CLEAR_PROJECT,
  DISPLAY_ALERT,
  GET_ITERATIONS_SUCCESS,
  GET_PROJECTS_SUCCESS,
  GET_TEST_CASES_SUCCESS,
  LOGIN_SUCCESS,
  LOGOUT_SUCCESS,
  SET_ITERATION,
  SET_LOADING,
  SET_PROJECT,
  UPDATE_USER_SUCCESS,
  GET_ORGANIZATION_MEMBERS_SUCCESS,
  CLEAR_ORGANIZATION_MEMBERS,
  GET_USERS_SUCCESS,
  CLEAR_USERS,
  CLEAR_USER,
  SET_USER,
  PASSWORD_CHANGE_SUCCESS,
  CLEAR_PROJECTS,
  CLEAR_ITERATIONS,
  CLEAR_TEST_CASES,
  GET_ORGANIZATIONS_SUCCESS,
  CLEAR_ORGANIZATIONS,
  SET_ORGANIZATION,
  CLEAR_ORGANIZATION,
} from "./Action";

const reducer = (state, action) => {
  const { type, payload } = action;
  switch (type) {
    case DISPLAY_ALERT:
      return {
        ...state,
        showAlert: true,
        alertType: payload.alertType,
        alertText: payload.alertText,
      };
    case SET_LOADING:
      return {
        ...state,
        isLoading: true,
      };

    case CLEAR_LOADING:
      return {
        ...state,
        isLoading: false,
      };

    case CLEAR_ALERT:
      return {
        ...state,
        showAlert: false,
        alertType: "",
        alertText: "",
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        isLoading: false,
        loggedInUser: payload.user,
        token: payload.token,
      };
    case LOGOUT_SUCCESS:
      return {
        isLoading: false,
        theme: "light",
        loggedInUser: null,
        token: null,
        organizations: null,
        totalOrganizations: 0,
        users: null,
        totalUsers: 0,
        organization: null,
        user: null,
        projects: null,
        totalProjects: 0,
        iterations: null,
        totalIterations: 0,
        testCases: null,
        totalTestCases: 0,
        project: null,
        iteration: null,
        orgMembers: null,
      };

    case UPDATE_USER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        loggedInUser: payload.user,
      };
    case PASSWORD_CHANGE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        loggedInUser: payload.user,
      };

    case GET_PROJECTS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        projects: payload.projects,
        totalProjects: payload.totalProjects,
      };
    case GET_ITERATIONS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        iterations: payload.iterations,
        totalIterations: payload.totalIterations,
      };
    case GET_TEST_CASES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        testCases: payload.testCases,
        totalTestCases: payload.totalTestCases,
      };
    case SET_PROJECT:
      return {
        ...state,
        isLoading: false,
        project: payload.project,
      };
    case SET_ITERATION:
      return {
        ...state,
        isLoading: false,
        iteration: payload.iteration,
      };
    case CLEAR_PROJECT:
      return {
        ...state,
        isLoading: false,
        project: null,
      };
    case CLEAR_ITERATION:
      return {
        ...state,
        isLoading: false,
        iteration: null,
      };
    case GET_ORGANIZATION_MEMBERS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        orgMembers: payload.orgMembers,
      };
    case CLEAR_ORGANIZATION_MEMBERS:
      return {
        ...state,
        isLoading: false,
        orgMembers: null,
      };

    case GET_USERS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        users: payload.users,
        totalUsers: payload.totalUsers,
      };

    case SET_USER:
      return {
        ...state,
        isLoading: false,
        user: payload.user,
      };

    case CLEAR_USERS:
      return {
        ...state,
        isLoading: false,
        users: null,
        totalUsers: 0,
      };

    case CLEAR_PROJECTS:
      return {
        ...state,
        isLoading: false,
        projects: null,
        totalProjects: 0,
      };

    case CLEAR_ITERATIONS:
      return {
        ...state,
        isLoading: false,
        iterations: null,
        totalIterations: 0,
      };

    case CLEAR_TEST_CASES:
      return {
        ...state,
        isLoading: false,
        testCases: null,
        totalTestCases: 0,
      };

    case CLEAR_USER:
      return {
        ...state,
        isLoading: false,
        user: null,
      };
    case GET_ORGANIZATIONS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        organizations: payload.organizations,
        totalOrganizations: payload.totalOrganizations,
      };
    case CLEAR_ORGANIZATIONS:
      return {
        ...state,
        isLoading: false,
        organizations: null,
        totalOrganizations: 0,
      };
    case SET_ORGANIZATION:
      return {
        ...state,
        isLoading: false,
        organization: payload.organization,
      };
    case CLEAR_ORGANIZATION:
      return {
        ...state,
        isLoading: false,
        organization: null,
      };

    default:
      return state;
  }
};

export default reducer;
